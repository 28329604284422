import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://9ca977fa205fb56a5e004bf3bc3a424d@o4507249704894464.ingest.de.sentry.io/4507367023640656",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [Sentry.browserTracingIntegration()],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});